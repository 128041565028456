<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      windowTop: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("scroll", this.scrollServicios);
    window.addEventListener("scroll", this.scrollAbout);
    window.addEventListener("scroll", this.changeRoutes);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("scroll", this.scrollServicios);
    window.removeEventListener("scroll", this.scrollAbout);
    window.removeEventListener("scroll", this.changeRoutes);
  },
  methods: {
    changeRoutes(event) {
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
      //console.log('fate '+offset) 3059.46vh
      if (this.$route.path == "/") {
        return "#LastProjects";
      } else if (this.$route.path == "/#LastProjects") {
        return "#Principal";
      }
    },


    onScroll(event) {/**673.076923076923 */
      this.windowTop = event.target.documentElement.scrollTop;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&family=Poppins:wght@300;400;500;600;700&display=swap");
:root {
  --color-1: #ff57da;
  --color-2: #7948ff;
  --color-3: #48deff;
  --color-4: #8d8d8d;
  --color-5: #ffffff;
  --color-6: #a17fff;
  --color-7: #f24e4e;
  --color-8: #a17fff;
  --color-9: #f54c49;
  --color-10: #3383b1;
  --color-11: #215675;

  --Poppins: "Poppins", sans-serif;
  --Lato: "Lato", sans-serif;


  --marketing: #EF67C2;
  --development: #3702FB;
  --desing: #6A00FD;
  --photography: #FFB6EA;
  --meet: #DA1E92;
  --entrepreurnship: #B2ACFC;
  overscroll-behavior: none;


  --size11mov: 2.657004830917874VW;

}


.bg_cat1{
  background-color: var(--marketing) !important
}
.bg_cat2{
  background-color: var(--development)  !important
}
.bg_cat3{
  background-color: var(--desing) !important
}
.bg_cat4{
  background-color: var(--photography) !important
}
.bg_cat5{
  background-color: var(--meet) !important
}
.bg_cat6{
  background-color: var(--entrepreurnship) !important
}

.col_cat1{
  color: var(--marketing) !important
}
.col_cat2{
  color: var(--development) !important
}
.col_cat3{
  color: var(--desing) !important
}
.col_cat4{
  color: var(--photography) !important
}
.col_cat5{
  color: var(--meet) !important
}
.col_cat6{
  color: var(--entrepreurnship) !important
}
/*iframe.If1 {
    width: 96vw!important;
}*/
.If1 ._2p3a {
    min-width: 100%!important;
    width: 100%!important;
}

.bor_cat1{
   color: var(--marketing) !important;
  border-bottom: 0.3vw solid var(--marketing) !important
}
.bor_cat2{
  color: var(--development) !important;
  border-bottom: 0.3vw solid var(--development) !important
}
.bor_cat3{
  color: var(--desing) !important;
  border-bottom: 0.3vw solid var(--desing) !important
}
.bor_cat4{

  color: var(--photography) !important;
  border-bottom: 0.3vw solid var(--photography) !important
}
.bor_cat5{
  color: var(--meet) !important;
  border-bottom: 0.3vw solid var(--meet) !important
}
.bor_cat6{
  color: var(--entrepreurnship) !important;
  border-bottom: 0.3vw solid var(--entrepreurnship) !important
}


  

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@100;200;300;400;500;600;700&display=swap");

@font-face {
  font-family: Gramatika-Medium;
  src: url("./assets/font/Gramatika-Medium.ttf");
  display: swap;
}

@font-face {
  font-family: Gramatika-Bold;
  src: url("./assets/font/Gramatika-Bold.ttf");
  display: swap;
}

@font-face {
  font-family: Gramatika-Regular;
  src: url("./assets/font/Gramatika-Regular.ttf");
  display: swap;
}

@font-face {
  font-family: Gramatika-Black;
  src: url("./assets/font/Gramatika-Black.ttf");
  display: swap;
}

@font-face {
  font-family: IBMPlex-Regular;
  src: url("./assets/font/IBMPlexSans-Regular.ttf");
  display: swap;
}

@font-face {
  font-family: IBMPlex-Bold;
  src: url("./assets/font/IBMPlexSans-Bold.ttf");
  display: swap;
}


:not(.container__en)::-webkit-scrollbar {
  width: 2vh !important;
  background: transparent;
  height: 1.26953125vh;
  margin: 0 0 3vh;
  z-index: 999;
  position: relative;
}

:not(.container__en)::-webkit-scrollbar-track {
  margin: 0 3vh 3vh;
  height: 2vh;
  width: 12vh;
  border-radius: 2vh;
  background: white;
}

:not(.container__en)::-webkit-scrollbar-thumb {
  background-color: #987ef3;
  border-radius: 2vh;
  border: 0.3vh solid #ffffff;
}

.pointer{
  cursor: pointer;
}
.ql-container.ql-snow,
.ql-toolbar.ql-snow {
  border: 0.1vw solid var(--color-2) !important;
}

.quill-editor-about {
  margin-bottom: 3vw;
  margin-top: 0.2vw;
  width: 56.9vw;
}

.img-item img {
  height: 8vw;
}

.video iframe {
  width: 50vw;
  height: 28vw;
}

.video-bloq {
  width: 55vw;
  height: auto;
  margin: auto;
}
.video-bloq iframe {
  width: 102% !important;
  height: 32vw !important;
}

html {
  scroll-behavior: smooth;
}

#app {
  margin: 0vw;
  height: 100vh;
  font-family: var(--Poppins);
}
#Partners .VueCarousel-wrapper {
    width: 99.5%!important;
}

.aic {
    align-items: center;
}
.ddm-sec p {
    font-family: 'Gramatika-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 2.604vw;
    line-height: 150.8%;
    text-align: center;
    color: #B2ACFC;
    margin: 0;
}
  .ddm-sec p.pc{
    display: block;
  }
  .ddm-sec p.mo{
    display: none;
  }
.ddm-sec p a {
    color: #EF67C2;
}

@media (max-width: 967px) {
  #app {
    margin: 0vw;
    height: 100vh;
    font-family: var(--Poppins);
  }
}

body {
  margin: 0px;
  overflow-x: hidden;
  overflow-y: overlay;
}
.gradientBK{
  transition: .5s;
}
/*
::-webkit-scrollbar {
  width: 15vh!important; 
  background: transparent;
  height: 1.26953125vh;
  margin: 0 0 3vh;
  z-index: 999;
  position: relative;
}

::-webkit-scrollbar-track {
  margin: 0 3vh 3vh;
  height: 2vh;
  width: 12vh; 
  border-radius: 2vh;
  background: white; 
}

::-webkit-scrollbar-thumb {
  background-color: #987ef3; 
  border-radius: 2vh; 
  border: .3vh solid #ffffff; 
}
*/
input:focus-visible {
  outline: none !important;
}

input,
textarea,
select {
  padding-left: 0.5vw;
  outline: none;
}

/* VUE AGILE CSS SECTION */
.MContent iframe {
    width: 57.91666666666667VW;
    height: 36.510416666666664VW;
}
.agile__list {
  display: block;
  overflow: hidden;
  position: relative;
  width: 87.3vw !important;
  margin: auto;
  padding-left: 0vw;
}
#PartnersC .agile__list{
  width: 100vh!important;
}
#PartnersC .agile__slide{
  width: auto!important;
}
.agile__slides.agile__slides--regular {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: unset;
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.agile__slide {
  display: block;
  flex-grow: 1;
  width: 21.89vw !important;
  flex-shrink: 0;
}
/*
.agile__actions {
    display: flex;
    justify-content: space-between;
    margin-top: -23vw;
    width: 94vw;
    margin-left: 2.5vw;
}
  button.agile__nav-button.agile__nav-button--next {
    color: #987EF3;
    background-color: rgba(255, 255, 255, 0);
    border-color: #987EF3;
    border-radius: 30vw;
    width: 4.635416666666667VW;
    height: 4.635416666666667VW;
    cursor: pointer;
    z-index: 1;
  }
  button.agile__nav-button.agile__nav-button--prev {
    color: #987EF3;
    background-color: rgba(255, 255, 255, 0);
    border-color: #987EF3;
    border-radius: 30vw;
    width: 4.635416666666667VW;
    height: 4.635416666666667VW;
    cursor: pointer;
    z-index: 1;
    font-size: 3vw;
  }*/
button.agile__nav-button.agile__nav-button--next {
  display: contents;
}
button.agile__nav-button.agile__nav-button--prev {
  display: contents;
}
.divPrev0 {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  background-color: rgba(255, 255, 255, 0);
  border-color: #987ef3;
  border-radius: 30vw;
  width: 4.635416666666667vw;
  height: 4.635416666666667vw;
  margin-left: 2.7vw;
  margin-right: -1.4vw;
  z-index: 1;
  cursor: pointer;
  transition: .5s;
}
.divNext0 {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  background-color: rgba(255, 255, 255, 0);
  border-color: #987ef3;
  border-radius: 30vw;
  width: 4.635416666666667vw;
  height: 4.635416666666667vw;
  margin-right: 2.7vw;
    margin-left: -1.6vw;
  z-index: 1;
  cursor: pointer;
  transition: .5s;
}
.divPrev0:hover,.divNext0:hover{
  border: 1px solid black;
  background-color: rgb(255, 255, 255);
  border-color: transparent;
}
.divPrev0:hover p.NButton,.divNext0:hover p.NButton{
  color: var(--marketing) !important;
}
p.NButton {
  color: #987ef3;
  font-size: 0.625vw;
  font-family: "Gramatika-Medium";
  font-weight: 900;
}
.VueCarousel-navigation-next {
    margin-top: -7vw;
}
.VueCarousel-navigation-prev {
    margin-top: -7vw;
}

.VideosD .agile__slide {
  display: block;
  flex-grow: 1;
  width: 29.1vw !important;
  flex-shrink: 0;
}
.VideosD .agile__actions {
  margin-top: -1vw;
  margin-top: -22vw;
  width: 91vw;
}

.cart_msg {
    font-size: .8vw;
}
.cart_success {
    color: #319f31;
}
.cart_error {
    color: #e34040;
}
@media (min-width:768px) {

#litesitespage .partners .VueCarousel-navigation-prev {
    top: 0% !important;
    margin-left: 14vw!important;
    margin-top: 9vw!important;
    filter: brightness(4.5)!important;
}
#litesitespage .partners .VueCarousel-navigation-next {
  top: 0% !important;
  margin-right: 14vw!important;
  margin-top: 9vw!important;
    filter: brightness(4.5)!important;

}
}
@media (max-width: 768px) {

.cart_msg {
    font-size: 2.8vw;
}
#litesitespage .partners .VueCarousel-navigation-prev {
    top: 0% !important;
    margin-left: 19vw!important;
    margin-top: 52vw!important;
    filter: brightness(4.5)!important;

}
#litesitespage .partners .VueCarousel-navigation-next {
  top: 0% !important;
  margin-right: 19vw!important;
  margin-top: 52vw!important;
    filter: brightness(4.5)!important;

}
.B4P_Video .VueCarousel-navigation .VueCarousel-navigation-prev{
    margin-left: 9vw;
    margin-top: 44vw;
}
.B4P_Video .VueCarousel-navigation .VueCarousel-navigation-next{
    margin-top: 44vw;
}

.customSlide {
  width: 88vw!important;
    height: auto;
    margin: 8vw auto 0!important;
}
.customSlide .VueCarousel-navigation {
    position: relative;
    z-index: 99;
}
.customSlide .VueCarousel-wrapper {
    width: 70vw;
    margin: auto;
}
.customSlide .VueCarousel-navigation .VueCarousel-navigation-prev{
    margin-left: 11vw;
    margin-top: -20vw;
}
.customSlide .VueCarousel-navigation .VueCarousel-navigation-next{
    margin-right: 12vw!important;
    margin-top: -20vw;
}
  .ddm-sec {
    width: 50.386vw;
}
  .ddm-sec p.pc{
    display: none;
  }
  .ddm-sec p.mo{
    display: block;
  }
  .ddm-sec p {
    font-size: 4.831vw;
    line-height: 150.8%;
}
  .MContent iframe {
    width: 70.916667VW;
    height: 143VW;
  }
.divPrev0 {
  border-radius: 30vw;
  width: 9.66183574879227VW;
  height: 9.66183574879227VW;
  margin-left: 2.7vw;
  margin-right: -1.4vw;
  z-index: 1;
}
.divNext0 {
  border-radius: 30vw;
  width: 9.66183574879227VW;
  height: 9.66183574879227VW;
  margin-right: 2.7vw;
  margin-left: -1.6vw;
  z-index: 1;
}
p.NButton {
  color: #987ef3;
  font-size: 2.829vw;
  font-weight: 900;
 /* margin-top: 3.768vw;*/
  margin-top: 0;
    margin-bottom: 0;
}
.VueCarousel-navigation-next {
  top: 0% !important;
  margin-right: 9vw !important;
    margin-top: -9vw;
}
.VueCarousel-navigation-prev {
  top: 0% !important;
  margin-left: 77vw;
    margin-top: -9vw;
}
#litesitespage .VueCarousel-navigation-prev {
    top: 0% !important;
    margin-left: 26vw;
    margin-top: 15vw;
}
#litesitespage .VueCarousel-navigation-next {
  top: 0% !important;
  margin-right: 26vw!important;
  margin-top: 15vw;
}
  /*#Blog:hover .video-head {
    transform: translateY(0);
}*/

  .divPrev {
    border: 1px solid black;
    background-color: rgba(255, 255, 255, 0);
    border-color: #987ef3;
    border-radius: 30vw;
    width: 9.66183574879227vw;
    height: 9.66183574879227vw;
    margin-top: -86vw;
    margin-left: 74vw;
    z-index: 1;
  }
  .divNext {
    border: 1px solid black;
    background-color: rgba(255, 255, 255, 0);
    border-color: #987ef3;
    border-radius: 30vw;
    width: 9.66183574879227vw;
    height: 9.66183574879227vw;
    margin-top: -86vw;
    margin-right: 4vw;
    z-index: 1;
  }

  .divPrev1 {
    border: 1px solid black;
    background-color: rgba(255, 255, 255, 0);
    border-color: #987ef3;
    border-radius: 30vw;
    width: 9.66183574879227vw;
    height: 9.66183574879227vw;
    margin-top: -44vw;
    margin-left: 4vw;
    z-index: 1;
  }
  .divNext1 {
    border: 1px solid black;
    background-color: rgba(255, 255, 255, 0);
    border-color: #987ef3;
    border-radius: 30vw;
    width: 9.66183574879227vw;
    height: 9.66183574879227vw;
    margin-top: -44vw;
    margin-right: 4vw;
    z-index: 1;
  }

  /*
    .agile__actions {
      display: flex;
      justify-content: space-between;
      margin-top: -88vw;
      width: 22vw;
      margin-left: 0vw;
      float: right;
    }
    button.agile__nav-button.agile__nav-button--prev {
      color: #987EF3;
      background-color: rgba(255, 255, 255, 0);
      border-color: #987EF3;
      border-radius: 30vw;
      width: 9.66183574879227VW;
      height: 9.66183574879227VW;
      cursor: pointer;
      z-index: 1;
      font-size: 3vw;
    }
    button.agile__nav-button.agile__nav-button--next {
      color: #987EF3;
      background-color: rgba(255, 255, 255, 0);
      border-color: #987EF3;
      border-radius: 30vw;
      width: 9.66183574879227VW;
      height: 9.66183574879227VW;
      cursor: pointer;
      z-index: 1;
      font-size: 3vw;
    }*/
  .agile__slide {
    display: block;
    flex-grow: 1;
    width: 41vw !important;
    flex-shrink: 0;
  }
  .un-portfolio-cont .agile__slide {
    width: 80.908vw !important;
  }
  .un-portfolio-cont .agile__list {
    width: 80.3vw !important;
    padding-left: 0vw;
  }
  .agile__list {
    width: 93.3vw !important;
    padding-left: 0vw;
  }

  .Agl_Vid .agile__slide {
    display: block;
    flex-grow: 1;
    width: 93.3vw !important;
    flex-shrink: 0;
    text-align: -webkit-center;
    
  }
  
}

/* AQUI TERMINA VUE AGILE CSS SECTION */

.dflx {
  display: flex;
}

.dpl {
  display: none;
}

a {
  text-decoration-color: transparent;
}

hr.hrhd {
  width: 70.72916666666667vw;
  margin-left: 0px;
  border-bottom: 0.2px solid #b1b1b1;
  margin-top: 1.6145833333333335vw;
  margin-bottom: 2.3166666666666665vw;
}

/** MENSAJES DE EXITO/ERROR */
.status_messages {
  margin-top: 1vw;
}

.msg p {
  color: var(--color-5);
  width: 20vw;
  padding: 0.5vw;
  font-family: var(--Poppins);
  font-size: 0.7333333333333334vw;
  font-style: normal;
}
.msg_success {
  background: var(--color-2);
  width: 20vw;
}

.msg_error p {
  background: var(--color-7);
  width: 20vw;
}
.swiper-wrapper {
  display: flex;
  width: 86.4vw !important;
}
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: "prev";
}
/** FIN MENSAJES DE EXITO/ERROR */

/** NAVIGATE BUTTONS */
#navigate-buttons {
  margin-left: 1.3vw;
  margin-top: 0.1vw;
}

#navigate-buttons button {
  border: 0px;
  background: var(--color-5);
  width: 1.5vw;
  height: 1.5vw;
  cursor: pointer;
  transition: all 300ms;
  color: var(--color-5);
  font-size: 1vw;
  margin-top: 0.7vw;
}

/*#navigate-buttons button:hover{
       
        background: var(--color-2);
       
        
    }*/
p.num-pages {
  color: #797979;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  padding-top: 0.2vw;
  font-size: 0.7vw;
}
p.cp {
  cursor: pointer;
}
/** FIN NAVIGATE BUTTONS */

/** MODAL delete*/

.modal_view {
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0vw;
  right: 0vw;

  z-index: 9000;
}
#modal_delete {
  /*background: var(--color-5);*/
  width: 16.383333vw;
  height: 8vw;
  position: absolute;
  top: 17vw;
  right: 40.5vw;
  padding: 0.8854166666666666vw;
}
#modal_delete .body {
  background: var(--color-1);
  width: 16.354166666666668vw;
  height: 6.354166666666666vw;
  border-radius: 1.5vw;
  justify-content: center;
}

#modal_delete h3 {
  font-size: 0.7291666666666666vw;
  color: var(--color-5);
  text-align: center;
  font-weight: 400;
  margin-top: 0vw;
}
#modal_delete h3 span {
  font-weight: 700;
}
#modal_delete img {
  margin-top: 1.0416666666666665vw;
  margin-left: 7.239583333333334vw;
  width: 1.9270833333333335vw;
  height: 1.8229166666666667vw;
}

.modal_del_btns {
  justify-content: center;
}

.modal_del_btns p {
  color: var(--color-2);
  text-decoration: underline;
  cursor: pointer;
  width: 5vw;
  text-align: center;
  padding: 0.5vw;
  margin: 0vw;
  font-size: 0.625vw;
}

/** fin modal delete */

/** modal add */
#modal_add {
  /*background: var(--color-5);*/
  width: 16.383333vw;
  height: 8vw;
  position: absolute;
  top: 17vw;
  right: 40.5vw;
  padding: 0.8854166666666666vw;
}
#modal_add .body {
  background: var(--color-1);
  width: 16.354166666666668vw;
  height: 2.3958333333333335vw;
  border-radius: 1.5vw;
}

#modal_add h3 {
  font-size: 0.7291666666666666vw;
  color: var(--color-5);
  padding-left: 1.5vw;
  font-weight: 400;
  padding-top: 0.6770833333333334vw;
  margin-top: 0vw;
  width: 10vw;
}
#modal_add img {
  padding-top: 0.54vw;
  padding-left: 3vw;
  width: 1.3031249999999999vw;
  height: 1.3031249999999999vw;
}

.modal_add_btns {
  justify-content: center;
}

.modal_add_btns p {
  color: var(--color-2);
  text-decoration: underline;
  cursor: pointer;
  width: 5vw;
  text-align: center;
  padding: 0.5vw;
  margin: 0vw;
  font-size: 0.625vw;
}
/** fin  modal add */

/** modal edit */
#modal_edit {
  /*background: var(--color-5);*/
  width: 16.383333vw;
  height: 8vw;
  position: absolute;
  top: 17vw;
  right: 40.5vw;
  padding: 0.8854166666666666vw;
}
#modal_edit .body {
  background: var(--color-1);
  width: 16.354166666666668vw;
  height: 2.3958333333333335vw;
  border-radius: 1.5vw;
}

#modal_edit h3 {
  font-size: 0.7291666666666666vw;
  color: var(--color-5);
  padding-left: 1.5vw;
  font-weight: 400;
  width: 13vw;
  padding-top: 0.6770833333333334vw;
  margin-top: 0vw;
}
#modal_edit img {
  padding-top: 0.54vw;

  width: 1.3031249999999999vw;
  height: 1.3031249999999999vw;
}

.img-item img {
  max-width: 15vw;
}

.modal_edit_btns {
  justify-content: center;
}

.modal_edit_btns p {
  color: var(--color-2);
  text-decoration: underline;
  cursor: pointer;
  width: 5vw;
  text-align: center;
  padding: 0.5vw;
  margin: 0vw;
  font-size: 0.625vw;
}
/** fin  modal add */

/** modal logout */

#modal_logout {
  /*background: var(--color-5);*/
  width: 16.383333vw;
  height: 8vw;
  position: absolute;
  top: 17vw;
  right: 40.5vw;
  padding: 0.8854166666666666vw;
}
#modal_logout .body {
  background: var(--color-2);
  width: 16.354166666666668vw;
  height: 6.354166666666666vw;
  border-radius: 1.5vw;
  justify-content: center;
}

#modal_logout h3 {
  font-size: 0.7291666666666666vw;
  color: var(--color-5);
  text-align: center;
  font-weight: 400;
  margin-top: 0.5vw;
}
#modal_logout h3 span {
  font-weight: 700;
}
#modal_logout img {
  margin-top: 1.0416666666666665vw;
  margin-left: 7.239583333333334vw;
  width: 1.875vw;
  height: 1.875vw;
}

.modal_log_btns {
  justify-content: center;
}

.modal_log_btns p {
  color: var(--color-2);
  text-decoration: underline;
  cursor: pointer;
  width: 5vw;
  text-align: center;
  padding: 0.5vw;
  margin: 0vw;
  font-size: 0.625vw;
}

p.noitems {
  color: var(--color-6);
  font-size: 0.625vw;
  font-weight: 500;
  width: 4.947916666666666vw;
  margin-right: 1.5625vw;
}
p.noitems span {
  font-weight: 700;
}

#select-items {
  width: 8.90625vw;
  border: 0px;
  background: var(--color-4);
  border-radius: 0.5vw;
  color: var(--color-5);
  font-size: 0.625vw;
  font-weight: 500;
  border: none;
  margin-right: 1.9270833333333335vw;
}
.activeradius {
  border-radius: 0.5vw 0.5vw 0vw 0vw !important;
}

#select-items option {
  background: white;
  color: #8d8d8d;
}
.buscador {
  margin-top: 0.2vw;
  height: 1.5625vw;
  border: 0.052083vw solid var(--color-4);
  border-radius: 0.5vw;
  padding-left: 0.5vw;
}
.buscador input {
  width: 15.312500000000002vw;
  border: none;
  height: 1.5625vw;
  color: #8d8d8d;
}
.icon_buscar img {
  width: 0.8854166666666666vw;
  height: 0.8854166666666666vw;
  padding-top: 0.3vw;
  margin-right: 0.5vw;
}

.btn_añadir {
  cursor: pointer;
  border: none;
  background: var(--color-1);
  color: var(--color-5);
  width: 15.416666666666668vw;
  height: 2.5vw;
  text-align: left;
  border-radius: 1.5vw;
  margin-top: 0.7291666666666666vw;
}
.btn_añadir img {
  width: 1.3031249999999999vw;
  height: 1.3031249999999999vw;

  padding-top: 0.6vw;
}
.btn_añadir p {
  width: 12vw;
  font-weight: 500;
  font-size: 0.7291666666666666vw;
  padding-bottom: 0.2vw;
  padding-left: 1.5vw;
}

.select-container {
  z-index: 2;
}

/** estilos scroll bar */
.tbl_item::-webkit-scrollbar {
  -webkit-appearance: none;
}

.tbl_item::-webkit-scrollbar:vertical {
  width: 0.3645833333333333vw;
}

.tbl_item::-webkit-scrollbar-button:increment,
.tbl_item::-webkit-scrollbar-button {
  display: none;
}

.tbl_item::-webkit-scrollbar:horizontal {
  height: 0.3645833333333333vw;
}

.tbl_item::-webkit-scrollbar-thumb {
  background-color: #797979;
  /*border-radius: 1.0416666666666665VW;*/
  border: 0.10416666666666667vw solid #f1f2f3;
}

.tbl_item::-webkit-scrollbar-track {
  border-radius: 0.3645833333333333vw;
}

.boxform {
  display: grid;
  margin-right: 4.375vw;
  margin-bottom: 0.8854166666666666vw;
}

p.btnRegresar {
  cursor: pointer;
  border: none;
  background: var(--color-1);
  color: var(--color-5);
  width: 6vw;
  padding: 0.7vw 0.7vw;
  text-align: center;
  border-radius: 1.5vw;
  margin-top: 0.7291666666666666vw;
  font-weight: 500;
  font-size: 0.7291666666666666vw;
  margin-right: 2vw;
}

button.alta {
  cursor: pointer;
  border: none;
  background: var(--color-1);
  color: var(--color-5);
  width: 6vw;
  height: 2.5vw;
  border-radius: 1.5vw;
  margin-top: 0.7291666666666666vw;
  font-weight: 600;
  font-size: 0.7291666666666666vw;
}

.btn_añadir2 {
  cursor: pointer;
  border: none;
  background: var(--color-1);
  color: var(--color-5);
  width: 11.1vw;
  height: 2.5vw;
  text-align: left;
  border-radius: 1.5vw;
  margin-top: 0.7291666666666666vw;
}
.btn_añadir2 img {
  width: 1.3031249999999999vw;
  height: 1.3031249999999999vw;
  margin-left: 1.5vw;
  padding-top: 0.6vw;
}
.btn_añadir2 p {
  font-weight: 500;
  font-size: 0.7291666666666666vw;
  padding-bottom: 0.2vw;
  padding-left: 1.5vw;
}

.btn_añadir2 p span {
  font-weight: 700;
}

#uploadimg button {
  border: none !important;
  background: var(--color-5);
}

input[type="file"] {
  display: none;
}

.filelabel {
  padding: 0.8vw 0vw;
  width: 14.716666666666668vw;
  border: 0.052083vw solid var(--color-2);
  border-radius: 0vw 1.5vw 1.5vw 0vw;
  color: var(--color-4) !important;
  font-size: 0.7291666666666666vw;
  font-weight: 500 !important;
  padding-left: 1.09375vw;
  cursor: pointer;
}

.filelabelname {
  padding: 0.8vw 1vw;
  border-left: none;
  width: auto;
  cursor: pointer;

  color: var(--color-4) !important;
  font-size: 0.7291666666666666vw;
  font-weight: 500 !important;
  padding-left: 1.09375vw;
}
p.snu {
  color: var(--color-4);
  text-align: center;
  font-size: 0.7vw;
}

.subtitle {
  color: var(--color-4);
  margin: 0px;
  font-size: 0.8333333333333334vw;
}

.subtitle2 {
  color: var(--color-4);
  font-weight: 600;
  font-size: 1.3020833333333335vw;
  margin: 0vw;
}

p.btnopciones {
  cursor: pointer;
  border: none;
  background: var(--color-3);
  color: var(--color-5);
  width: 10vw;
  text-align: center;
  transition: all 300ms;
  margin-right: 0.1vw;

  font-weight: 500;
  font-size: 0.7291666666666666vw;
  padding-bottom: 0.5vw;
  padding-top: 0.5vw;
}

.btnsgrp p:first-child {
  border-radius: 1.5vw 0vw 0vw 1.5vw;
}
.btnsgrp p:last-child {
  border-radius: 0vw 1.5vw 1.5vw 0vw;
}
p.btnopciones:hover {
  background: var(--color-2);
}
/** border-radius: 1.5vw; */

/*select */

.select-container {
  margin-right: 1.9270833333333335vw;
}
#select-items {
  width: 8.90625vw;
  height: 1.563vw;
  margin-top: 0.2vw;
  border: 0px;
  background-color: var(--color-4);
  border-radius: 0.5vw;
  color: var(--color-5);
  font-size: 0.7291666666666666vw;
  font-weight: 500;
  border: none;
  padding-left: 0.938vw;
  display: flex;
  align-items: center;
  background-image: url("./assets/flecha_select.png");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 8.6vw;
}
input#select-ch,
input#select-ch2,
input#select-ch3,
input#select-ch4,
input#select-ch5,
input#select-ch6,
input#select-ch7 {
  display: none;
}
.options-cont {
  display: none;
}
#select-ch:checked ~ .options-cont,
#select-ch2:checked ~ .options-cont,
#select-ch3:checked ~ .options-cont,
#select-ch4:checked ~ .options-cont,
#select-ch5:checked ~ .options-cont,
#select-ch6:checked ~ .options-cont,
#select-ch7:checked ~ .options-cont {
  display: flex;
  position: absolute;
  padding: 0.573vw 1.25vw;
  flex-direction: column;
  background-color: white;
  width: 7.3vw;
}
#select-items.rounded {
  border-radius: 0.5vw 0.5vw 0 0;
}
.option-btn {
  cursor: pointer;
  font-size: 0.625vw;
  line-height: 1.25vw;
}
.option-btn:hover {
  background: gainsboro;
}

.activeradius {
  border-radius: 0.5vw 0.5vw 0vw 0vw !important;
}

#select-items option {
  background: white;
  color: #8d8d8d;
}

/*select */
</style>
